import { EHomePageView } from '@/components/common/HomePage/lib/EHomePageView.enum';
import { useAdsView } from '@/hooks/useAdsView';
import { useDefaultNavigator } from '@/hooks/useDefaultNavigator';
import { useScrollToElement } from '@/hooks/useScrollToElement';
import { GetLatestMarketDataResponseDto } from '@/services/models/GetLatestMarketDataResponseDto';
import { HomeTokenAdBanner } from '../ads/homeTokenAd/HomeTokenAdBanner';
import { MarketCapPageUI } from './MarketCapPageUI';
import { useMarketCap } from './useMarketCap';

export const MarketCapPage = ({ marketCapTokensList }: { marketCapTokensList: GetLatestMarketDataResponseDto[] }) => {
  const { pushSearchString } = useDefaultNavigator();
  const { elementRef, scrollToElement } = useScrollToElement(-70);

  const { tokenAdsView, tokenAdsViewLoading } = useAdsView('marketCap');

  const { currentPageItems, toggleSorting, paginationData, updateCurrentPageIndex, tableSort } =
    useMarketCap(marketCapTokensList);

  return (
    <div ref={elementRef}>
      <HomeTokenAdBanner tokenAdsView={tokenAdsView} isLoading={tokenAdsViewLoading} />
      <MarketCapPageUI
        currentPage={paginationData.currentIndex + 1}
        totalItems={paginationData.totalLength}
        itemsPerPage={paginationData.itemsPerPage}
        currentPageItems={currentPageItems}
        tabId={EHomePageView.TOP_BY_MARKET_CAP}
        totalPages={paginationData.pagesLength}
        onChangePageNumber={(newPage) => {
          pushSearchString(updateCurrentPageIndex(newPage - 1));
          setTimeout(scrollToElement, 10);
        }}
        onSortChange={(column) => {
          pushSearchString(toggleSorting(column));
          scrollToElement();
        }}
        sortingColumn={tableSort.column}
        sortingOrder={tableSort.order}
      />
    </div>
  );
};
