import clsx from 'clsx';
import { Table, TableCell } from 'moralis-ui';
import { TableSortIcon } from '@/components/common/TableSortIcon';
import { GetLatestMarketDataResponseDto } from '@/services/index';
import { SortOrder } from '@/utils/sorting';
import { t } from '@lingui/core/macro';
import { MarketCapPageTableRowUI } from './MarketCapPageTableRowUI';
import { ValidColumnsMarketCap } from './ValidColumnsMarketCap';
import tableTokenStyle from './MarketCapPageTableRowUI.module.scss';
import styles from './MarketCapPageUI.module.scss';

export const MarketCapTableUI = ({
  sortingColumn,
  sortingOrder,
  onSortChange,
  startIndex,
  items,
}: {
  onSortChange: (column: ValidColumnsMarketCap) => void;
  sortingColumn: ValidColumnsMarketCap;
  sortingOrder: SortOrder;
  startIndex: number;
  items: GetLatestMarketDataResponseDto[];
}) => {
  const renderSortableHeader = (label: string, column: ValidColumnsMarketCap) => (
    <TableCell align="left" aria-label={column} variant="headerCell">
      <span
        className={clsx('caption-14-medium', styles.tokenTableHeader, styles.tokenTableHeaderFilters)}
        onClick={() => onSortChange(column)}
      >
        {label}
        <TableSortIcon isSelected={column === sortingColumn} isAsc={sortingOrder === SortOrder.ASC} />
      </span>
    </TableCell>
  );
  return (
    <Table
      transparent
      className={styles.mainPageTable}
      hasBorder={false}
      size="md"
      tbodyCells={items.map((token, index) => (
        <MarketCapPageTableRowUI
          key={`${token.address}-${token.name}-${token.symbol}`}
          token={token}
          rowNumber={startIndex + index + 1}
        />
      ))}
      theadCells={
        <tr>
          <TableCell align="left" aria-label="tokenInfo" className={tableTokenStyle.fieldToken} variant="headerCell">
            <span className={clsx('caption-14-medium', styles.tokenTableHeader)}>
              {t({
                message: '# Token',
                context: 'Header for the token column in the table, indicating token name or symbol',
              })}
            </span>
          </TableCell>
          {renderSortableHeader(
            t({
              message: 'Price',
              context: 'Column displaying the current price of the token',
            }),
            ValidColumnsMarketCap.currentPrice,
          )}
          {renderSortableHeader(
            t({
              message: '1H',
              context: 'Price change percentage over the last 1 hour',
            }),
            ValidColumnsMarketCap.priceChangePercentage1h,
          )}
          {renderSortableHeader(
            t({
              message: '24H',
              context: 'Price change percentage over the last 24 hours',
            }),
            ValidColumnsMarketCap.priceChangePercentage24h,
          )}
          {renderSortableHeader(
            t({
              message: '7D',
              context: 'Price change percentage over the last 7 days',
            }),
            ValidColumnsMarketCap.priceChangePercentage7d,
          )}
          {renderSortableHeader(
            t({
              message: 'Volume (24H)',
              context: 'Total trading volume of the token over the last 24 hours',
            }),
            ValidColumnsMarketCap.totalVolume,
          )}
          {renderSortableHeader(
            t({
              message: 'Market Cap',
              context: 'Market capitalization of the token',
            }),
            ValidColumnsMarketCap.marketCap,
          )}
        </tr>
      }
    />
  );
};
